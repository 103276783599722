@font-face {
  font-family: 'Monserrat';
  src: local('Monserrat'), url(./assets/fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: normal;

}
@font-face {
  font-family: 'Monserrat';
  src: local('Monserrat'), url(./assets/fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

main {
  background-color: #f7f7f7;
  overflow: hidden;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.configuration-box{
  padding:1em;
}

.fractals-container{
  padding-top: 50px;
}

.fractal-canvas {
  max-width: 100%;
  transform : scale(0.15);
  -webkit-transform : scale(0.15);
  transform-origin : 0 0;
  -webkit-transform-origin : 0 0;
  border:solid 1px #333;
}

.configuration-box-inner{

}

/*  HOME PAGE  */

.main-content{
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.main-content-container{
  width: 100%;
  max-width: 1100px;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.content-row-img{
  width: 100%;
}

.content-row{
  margin-top: 2em;
}

@media (min-width: 1110px) {
  .text-container{
    padding:1em 10em 5em 1em;
  }
  .content-row{
    padding: 2em;
  }
}
@media (min-width: 768px) {
  .text-container{
    padding:1em 3em 3em 1em;
  }
  
}
@media (max-width: 767px) {
  .text-container{
    padding:1em 1em 1em 1em;
  }
}


.picture-container{
  text-align: center; 
  align-items: center;
  display: flex;
  padding: 5px;
  justify-content: center;
}

.first-fold-container{
  height: 50em;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  text-align: center;

  background: white;
}

.main-header{
  font-size: 4em;
}

/*  GENERAL  **/

body{
}

.ant-menu-overflow{

  align-items: center;
  align-content: center;
  justify-content: center;
}

.root{
  font-family: "Monserrat", serif !important;
  }
  
  body{
  font-family: "Monserrat", serif !important;
  }

  h1,h2,h3,h4,h5,h6,p,button,a {
    font-family: "Monserrat", sans-serif !important;
  }


.loading-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  z-index: 10;
  background-color: rgba(76, 76, 76, 0.189);
  display: flex;
  vertical-align: middle;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: none;
  
}

.loading-overlay-container {
  display: inline-block;
  background-color:white
}

.loading-overlay-container-inner {
  padding: 1em;
}

.canvas-buttons{
  padding: 1em 0;
}

.canvas-buttons button {
  margin: 0em 0.5em;
}

.mandala-settings-container{
  padding: 0.5em;
  text-align: center;
}
.mandala-settings-container-inner{
  padding: 1.5em;
  text-align: left;
  background-color: white;
}

.canvas-side-container{
  padding: 0.5em;
  text-align: center;
}

.canvas-side-container-inner{
  padding: 1.5em;
  display: flex;
  /* align-items: center; */
  /* align-content: center; */
  /* justify-content: center; */
  flex-flow: column;
  /* flex-wrap: wrap; */
  background-color: white;
}